import React from 'react';

function Header({ opened, open }) {

    return (
        <>
            <header id="header">
                <div className="logo-c">
                    <a href="#main">
                        <img src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fcila-logo-blanco.png?alt=media" className="logo" alt="" />
                    </a>
                </div>
                <a className="main-link header-link" href="/#main"><span>Index</span></a>
                <a className="services-link header-link" href="/#services"><span>Services</span></a>
                <a className="about-us-link header-link" href="/#about-us"><span>About us</span></a>
                <a className="contact-link header-link" href="/#contact"><span>Contact</span></a>
                <div id="menu-trigger" className="menu-trigger" onClick={() => open(!opened)}>
                    <i className="fas fa-bars fa-3x"></i>
                </div>
            </header>
            <div className={"menu-list" + (opened ? " menu-animation" : "")} id="menu-list">
                <ul>
                    <li>
                        <a href="/#services" ><span>Services</span></a>
                    </li>
                    <li>
                        <a href="/#about-us"><span>About us</span></a>
                    </li>
                    <li>
                        <a href="/#contact"><span>Contact</span></a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Header;
