import React, { useRef } from 'react';
import Helmet from 'react-helmet';
import './index.sass';
import Footer2 from '../../footer-2';
import Header2 from '../../header-2';
import MenuBar from '../../menu-bar';
import useOnScreen from '../../hooks/useOnScreen';

const ServicesWeb = () => {

  const [video, fContent] = [useRef(), useRef()],
    videoVisible = useOnScreen(video),
    fContentV = useOnScreen(fContent);

  return (
    <article className="about-us-page">
      <Helmet>
        <title>
          Cilasolutions | Services
        </title>
      </Helmet>
      <section className="about-us-section">
        <Header2 />
        <div ref={fContent} className={`about-us-grid ${fContentV && 'f-s-g-v'}`}>
          <div className="first-title-c">
            <h1 className="first-title">
              Our Offering
            </h1>
          </div>
          <div className="content-c hidden-left" style={{ alignItems: 'flex-start' }}>
            <h3 className="subtitle-about-us">
              NEARSHORE DEVELOPMENT
            </h3>
            <p>
              Bringing together interdisciplinary teams to ensure that client's projects are successful.
            </p>
            <h3 className="subtitle-about-us">
              WEB & MOBILE APP DEVELOPMENT
            </h3>
            <p>
              Expertise on state of the art technologies to take client´s business to the next level.
            </p>
          </div>
          <div className="content-video">
            <div ref={video} className={`img-c-about-us ${videoVisible && 'videoLoaded'}`}>
              <img className="section-header-img-about-us"
                srcSet="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-350.png?alt=media 350w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-400.png?alt=media 400w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-450.png?alt=media 450w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-500.png?alt=media 500w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-550.png?alt=media 550w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-600.png?alt=media 600w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-650.png?alt=media 650w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-700.png?alt=media 700w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices.png?alt=media 750w"
                sizes="(max-width: 350px) 350px,
                        (max-width: 400px) 400px,
                        (max-width: 450px) 450px,
                        (max-width: 500px) 500px,
                        (max-width: 550px) 550px,
                        (max-width: 600px) 600px,
                        (max-width: 650px) 650px,
                        (max-width: 700px) 700px,
                        750px"
                src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices.png?alt=media" alt="services" />
            </div>
          </div>
        </div>
        <Footer2 />
      </section>
      <MenuBar></MenuBar>
    </article>
  );
}

export default ServicesWeb;
