import React, { useRef } from "react";
import Helmet from "react-helmet";
import Footer2 from "../footer-2";
import Header2 from "../header-2";
import useOnScreen from "../hooks/useOnScreen";
import MenuBar from "../menu-bar";
import "./draft.sass";
import { motion } from "framer-motion";

function WebContent() {
  const [video, fContent] = [useRef(), useRef()],
    videoVisible = useOnScreen(video);

  return (
    <div className="new-web-content">
      <Helmet>
        <title>CilaSolutions | Software talent - Nearshore development</title>
      </Helmet>
      <section className="first-section">
        <Header2 />
        <div ref={fContent} className={`first-section-grid`}>
          <div>
            <motion.h1
              className="first-title"
              initial={{ left: 0, transform: "translateX(-100%)" }}
              whileInView={{
                position: "relative",
                left: "unset",
                transform: "none",
              }}
              viewport={{ once: true }}
            >
              Nearshore
            </motion.h1>
            <h2 className="second-title">Solutions for your IT teamwork</h2>
          </div>
          <div style={{ margin: "0 -15% 0 -15%" }}>
            <div
              ref={video}
              className={`videoContainer ${videoVisible && "videoLoaded"}`}
            >
              <video autoPlay={true} muted={true} loop={true}>
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Foutput.mp4?alt=media"
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </div>
          <div>
            <motion.p
              initial={{ left: 0, transform: "translateX(-100%)" }}
              whileInView={{
                position: "relative",
                left: "unset",
                transform: "none",
              }}
              viewport={{ once: true }}
              style={{
                fontFamily: "Minion",
                fontSize: "1.7em",
                textAlign: "center",
              }}
            >
              CilaSolutions is a software development services company that
              teams up with North American based software developers to augment
              their teams with senior programming experience delivered as an
              outsourced service.
            </motion.p>
          </div>
        </div>
      </section>
      <section
        className="content"
        style={{ borderTop: "5px rgba(236, 31, 38, 0.9) solid" }}
      >
        <h2 id="services" className="third-title">Services</h2>
        <div className="offer" id="services">
          <div>
            <div style={{ textAlign: "center" }}>
              <span className="web-span">Web development</span>
            </div>
            <div style={{ overflow: "hidden" }}>
              <a href="web-development">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  alt=""
                  srcSet="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fweb-500.jpg?alt=media 500w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fweb-1000.jpg?alt=media 1000w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fweb-1500.jpg?alt=media 1500w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fweb-2000.jpg?alt=media 2000w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fweb-2500.jpg?alt=media 2500w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fweb-3500.jpg?alt=media 3500w"
                  sizes="(max-width: 1000px) 500px,
                   (max-width: 1500px) 1000px,
                   (max-width: 2000px) 1500px,
                   (max-width: 2500px) 2000px,
                   (max-width: 3000px) 2500px,
                   3500px"
                />
              </a>
            </div>
          </div>
          <div>
            <div style={{ overflow: "hidden" }}>
              <a href="app-development">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  alt=""
                  srcSet="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fapp-500.jpg?alt=media 500w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fapp-1000.jpg?alt=media 1000w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fapp-1500.jpg?alt=media 1500w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fapp-2000.jpg?alt=media 2000w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fapp-3000.jpg?alt=media 3000w"
                  sizes="(max-width: 1000px) 500px,
                   (max-width: 1500px) 1000px,
                   (max-width: 2000px) 1500px,
                   (max-width: 2500px) 2000px,
                   3000px"
                />
              </a>
            </div>
            <div style={{ textAlign: "center" }}>
              <span className="web-span">Mobile app development</span>
            </div>
          </div>
          <div>
            <div style={{ textAlign: "center" }}>
              <span className="web-span">Nearshoring</span>
            </div>
            <div style={{ overflow: "hidden" }}>
              <a href="nearshore">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                  alt=""
                  srcSet="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fnearshore-700.jpg?alt=media 700w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fnearshore-1400.jpg?alt=media 1400w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fnearshore-2100.jpg?alt=media 2100w,
                    https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fnearshore.jpg?alt=media 7000w"
                  sizes="(max-width: 1200px) 700px,
                   (max-width: 2400px) 1400px,
                   (max-width: 3600px) 2100px,
                   7000px"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="second">
                <div>CilaSolutions is a software development services company that teams up with North American based software developers to augment their teams with senior programming experience delivered as an outsourced service.</div>
                <div className="circle-m center-hz-abs r-10">
                    <img src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fapp-1000.jpg?alt=media"></img>
                </div>

            </section> */}
      <MenuBar />
      <Footer2 />
    </div>
  );
}

export default WebContent;
