import React, { useRef } from 'react';
import Helmet from 'react-helmet';
import './index.sass';
import Footer2 from '../../footer-2';
import Header2 from '../../header-2';
import MenuBar from '../../menu-bar';
import useOnScreen from '../../hooks/useOnScreen';

const AboutUs = () => {

  const [video, fContent] = [useRef(), useRef()],
    videoVisible = useOnScreen(video),
    fContentV = useOnScreen(fContent);

  return (
    <article className="about-us-page">
      <Helmet>
        <title>
          Cilasolutions | About us
        </title>
      </Helmet>
      <section className="about-us-section">
        <Header2 />
        <div ref={fContent} className={`about-us-grid ${fContentV && 'f-s-g-v'}`}>
          <div className="first-title-c">
            <h1 className="first-title">
              About Us
            </h1>
          </div>
          <div className="content-c hidden-left" style={{ alignItems: 'flex-start' }}>
            <h3 className="subtitle-about-us">
              Staffing provided by Colombian-based developers
            </h3>
            <p>
              CilaSolutions is a software development services company that teams up with North American based software developers to augment their teams with senior programming experience delivered as an outsourced service.
            </p>
          </div>
          <div className="content-video">
            <div ref={video} className={`img-c-about-us ${videoVisible && 'videoLoaded'}`}>
              <img className="section-header-img-about-us" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fabout-us.jpg?alt=media" alt="about-us" />
            </div>
          </div>
        </div>
        <Footer2 />
      </section>
      <MenuBar></MenuBar>
    </article>
  );
}

export default AboutUs;