import React from 'react';
import Helmet from 'react-helmet';
import './index.sass';
import Footer2 from '../../footer-2';
import Header2 from '../../header-2';
import MenuBar from '../../menu-bar';

const Nearshore = () => {

  return (
    <div className="nearshore-page">
      <Header2 />
      <Helmet>
        <title>
          Cilasolutions | Nearshore
        </title>
      </Helmet>
      <div className="page-container">
        <h1 className="nearshore-title">Nearshore</h1>
        <h1 className="nearshore-title outline">Development</h1>
        <div className="nearshore-content">
          <div className="nearshore-text-container">
            <figure className="nearshore-figure">
              <img src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2FNearshore-shape.png?alt=media&token=03083879-34aa-475e-8bd9-956656846f96" className="img-clip-nearshore" />
            </figure>
            <p className="nearshore-text" style={{marginTop: '2em'}}>
              In today's market, growing companies face serious, challenges in finding, the right talent.
              Therefore, it is more important than ever for companies to understand the opportunity that a recruitment strategy can offer.
            </p>
            <p className="nearshore-text outline">
              CilaSolutions provides talented professional programmers to software development companies seeking additional support or project resources.
            </p>
          </div>
        </div>
        <img className="nearshore-bg" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fnearshore-bg-x.jpg?alt=media&token=232dff6d-d375-4ca6-b733-bc249a001209" />
        <div className="nearshore-bg on-top"></div>
      </div>
      <Footer2 />
      <MenuBar />
    </div>
  );
}

export default Nearshore;
