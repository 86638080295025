import React from "react";
import Wave from "../web-content/wave";
import './index.sass';

const Footer2 = (props) => {
  return (
    <>
      <div className="inv-curve-2">
        <Wave />
      </div>
      <div className="inv-curve">
        <div className="footer-info">
          <div className="footer-info-content">
            <div>
              1st street #11E-76,
            </div>
            <div>
              San José de Cúcuta, Colombia.
            </div>
            <div>
              ( +57 ) 311 282 9985 - 607 5729831.
            </div>
            <div>
              <a href="contact">Contact us</a>
            </div>
          </div>
        </div>
        <Wave />
      </div>
    </>
  );
}

export default Footer2;
