import React, { useState } from 'react';
import './jquery.pagepiling.css';
import './index.sass';
import Header from './header/header';
import MobileContent from './mobile-content/mobile-content';
import WebContent from './web-content/web-content';
import $ from 'jquery';
import { pagepiling } from './jquery.pagepiling';
import { Route, Switch } from 'react-router-dom';
import ServicesMobile from './pages/services/services-mobile';
import ServicesWeb from './pages/services/services-web';
import Nearshore from './pages/nearshore'
import Contact from './pages/contact';
import AboutUs from './pages/about-us';
import useOnLoad from './hooks/onLoad';
import WebDevelopment from './pages/web-development';
import AppDevelopment from './pages/app-development';

const App = () => {

  const [menuOpened, setMenuOpened] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const resized = () => {
    setIsMobile(window.innerWidth <= 512 ? true : false);
  }

  const hashChanged = () => {
    setMenuOpened(false);
  }

  const loaded = useOnLoad();

  useState(() => {
    if (typeof window !== 'undefined') {
      resized();
      window.addEventListener("resize", resized);
      pagepiling($, document, window);
      $(window).on('hashchange', hashChanged);
    }
    return () => window.removeEventListener("resize");
  }, []);

  return (
    <div style={{visibility: loaded ? 'visible' : 'hidden'}}>
      <Switch>
        <Route exact path="/services">
          {isMobile &&
            <ServicesMobile />
          }
          {!isMobile && <ServicesWeb />}
        </Route>
        <Route exact path="/nearshore">
          <Nearshore />
        </Route>
        <Route exact path="/web-development">
          <WebDevelopment />
        </Route>
        <Route exact path="/app-development">
          <AppDevelopment />
        </Route>
        <Route exact path="/contact">
          {!isMobile && <Contact />}
        </Route>
        <Route exact path="/about-us">
          {!isMobile && <AboutUs />}
        </Route>
        {!isMobile && <WebContent />}
        <Route exact path="/">
          {isMobile &&
            (<>
              <Header open={setMenuOpened} opened={menuOpened} />
              <MobileContent />
            </>)
          }
          {!isMobile && <WebContent />}
        </Route>
      </Switch>
    </div>
  )
};

export default App;
