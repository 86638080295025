import React from 'react';
import './index.sass';

const MenuBar = (props) => {

  return (
    <ul className="menu-bar">
      <li>
        <div className="menu-bar-item">
          <a href="/" style={{ color: '#00C5D4' }}>Index</a>
          <div className="menu-bar-head f-c-m" style={{ background: '#00C5D4' }}>
            <i className="fas fa-home"></i>
          </div>
        </div>
      </li>
      <li>
        <div className="menu-bar-item">
          <a href="/#services" style={{ color: '#EC1F26' }}>Services</a>
          <div className="menu-bar-head f-c-m" style={{ background: '#EC1F26', color: 'white' }}>
            <i className="fas fa-concierge-bell"></i>
          </div>
        </div>
      </li>
      <li>
        <div className="menu-bar-item">
          <a href="/about-us" style={{ color: '#606062' }}>About us</a>
          <div className="menu-bar-head f-c-m" style={{ background: '#606062', color: 'white' }}>
            <i className="fa fa-dove"></i>
          </div>
        </div>
      </li>
      <li>
        <div className="menu-bar-item">
          <a href="/contact" style={{ color: '#096062' }}>Contact us</a>
          <div className="menu-bar-head f-c-m" style={{ background: '#096062', color: 'white' }}>
            <i className="fa fa-comment"></i>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default MenuBar;
