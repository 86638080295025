import { useState, useEffect } from 'react';

export default function useOnLoad() {

  const [hadLoaded, setHadLoaded] = useState(false);

  useEffect(()=> {
    window.onload = (event) =>{
      setTimeout(() => {
        const a = document.getElementsByClassName('telon')[0];
        a.remove();
        setHadLoaded(true);
      }, 200)
    }
  }, []);

  return hadLoaded;
}
