import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import $ from 'jquery';
import './index.sass';
import Footer2 from '../../footer-2';
import Header2 from '../../header-2';
import MenuBar from '../../menu-bar';

const Contact = () => {

  useEffect(() => {

    $("#cila-contact-form").submit(function () {
      $(".cila-send-button").attr("disabled", true);
      return true;
    });
  }, []);

  return (
    <div className="contact-page">
      <Helmet>
        <title>
          Cilasolutions | Contact us
        </title>
      </Helmet>
      <Header2 />
      <form id="cila-contact-form" className="cila-contact-form" action="https://formspree.io/xnqgkodd" method="POST">
        <article className="contact-grid">
          <div className="legend">
            <div>
              <span>
                Interested in growing your IT team with CilaSolutions?
              </span>
            </div>
          </div>
          <div className="f-c-1 f-c-m">
            <label className="cila-contact-form-label">
              Your email
            </label>
          </div>
          <div className="f-c-1 f-c-m">
            <input className="cila-contact-form-input" type="text" name="email" required />
          </div>
          <div className="f-c-1 f-c-m">
            <label className="cila-contact-form-label">
              Your contact number
            </label>
          </div>
          <div className="f-c-1 f-c-m">
            <input className="cila-contact-form-input" type="text" name="telephone" />
          </div>
          <div style={{ position: 'relative' }}>
            <label className="cila-contact-form-label">
              Your message
            </label>
            <div className="contact-img">
              <img src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fcontact-us-blue.png?alt=media" />
            </div>
          </div>
          <div className="f-c-3 f-c-m">
            <textarea className="cila-contact-form-input cila-textarea" name="message"
              required></textarea>
          </div>
          <div className="f-c-1 f-c-m send-button">
            <button className="cila-send-button" type="submit">Send</button>
          </div>
        </article>
      </form>
      <Footer2 />
      <MenuBar></MenuBar>
    </div>
  );
}

export default Contact;