import React from 'react';
import Helmet from 'react-helmet';
import './index.sass';
import Footer2 from '../../footer-2';
import Header2 from '../../header-2';
import MenuBar from '../../menu-bar';

const AppDevelopment = () => {

  return (
    <div className="app-development-page">
      <Header2 />
      <Helmet>
        <title>
          Cilasolutions | App Development
        </title>
      </Helmet>
      <div className="app-development-page-container">
        <h1 className="app-development-title" style={{fontSize: '3.5em'}}>App</h1>
        <h1 className="app-development-title">Development</h1>
        <div className="app-development-content">
          <div className="app-development-text-container">
            <p className="app-development-text" style={{marginTop: '2em'}}>
              Expertise in cutting-edge technologies to take the customer’s business to the next level. We know how to build it, make it beautiful and easy to use.
            </p>
          </div>
        </div>
        <img className="app-development-bg" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fcila-app-development-x.png?alt=media&token=91a8c803-64dc-47d0-9413-aeb7dd55324c" />
        {/* <div className="app-development-bg on-top"></div> */}
      </div>
      <Footer2 />
      <MenuBar />
    </div>
  );
}

export default AppDevelopment;
