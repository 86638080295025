import React from 'react';
import Helmet from 'react-helmet';
import './index.sass';
import Footer2 from '../../footer-2';
import Header2 from '../../header-2';
import MenuBar from '../../menu-bar';

const WebDevelopment = () => {

  return (
    <div className="web-page">
      <Header2 />
      <Helmet>
        <title>
          Cilasolutions | Web Development
        </title>
      </Helmet>
      <div className="web-page-container">
        <h1 className="web-title" style={{fontSize: '3.5em'}}>Web</h1>
        <h1 className="web-title">Development</h1>
        <div className="web-content">
          <div className="web-text-container">
            <p className="web-text" style={{marginTop: '2em'}}>
              We help you choose the most appropriate technology stack and engage scalable and adaptable hosting solutions for your current and future growth.
            </p>
          </div>
        </div>
        <img className="web-development-bg" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fweb-development-x.jpg?alt=media&token=4ff5cd0f-536d-482f-8856-e300628f0b61" />
        <div className="web-development-bg on-top"></div>
      </div>
      <Footer2 />
      <MenuBar />
    </div>
  );
}

export default WebDevelopment;
