import React from 'react';
import Wave from '../web-content/wave';
import './index.sass';

const Header2 = (props) => {
  
  return (
    <div className="heading">
      <img src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fcila-logo-blanco-2.png?alt=media"></img>
      <div className="curve">
        <Wave />
      </div>
      <div className="curve-2">
        <Wave />
      </div>
    </div>
  );
}

export default Header2;
