import $ from 'jquery';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

function MobileContent() {

    useEffect(() => {
        $('#pagepiling').pagepiling({
            navigation: false,
            sectionSelector: 'section',
            anchors: ['main', 'services', 'about-us', 'contact', 'footer']
        });

        $("#cila-contact-form").submit(function () {
            $(".cila-send-button").attr("disabled", true);
            return true;
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>CilaSolutions | Software talent - Nearshore development</title>
            </Helmet>
            <div id="pagepiling">
                <section name="main" id="main" className="main">
                    <div className="section-content">
                        <div className="section-header">
                            <div className="img-c-header">
                                <img className="section-header-img" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fmain.jpg?alt=media" alt="main" />
                            </div>
                        </div>
                        <div className="section-core">
                            <div className="f-c-1 f-c-m">
                                <div className="section-title">Solutions for your IT teamwork</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section name="services" id="services" className="services">
                    <div className="section-content">
                        <div className="section-header">
                            <div className="img-c-header">
                                <img className="section-header-img services-image"
                                    srcSet="
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-350.png?alt=media 350w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-400.png?alt=media 400w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-450.png?alt=media 450w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-500.png?alt=media 500w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-550.png?alt=media 550w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-600.png?alt=media 600w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-650.png?alt=media 650w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices-700.png?alt=media 700w,
                            https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices.png?alt=media 750w"
                                    sizes="(max-width: 350px) 350px,
                        (max-width: 400px) 400px,
                        (max-width: 450px) 450px,
                        (max-width: 500px) 500px,
                        (max-width: 550px) 550px,
                        (max-width: 600px) 600px,
                        (max-width: 650px) 650px,
                        (max-width: 700px) 700px,
                        750px"
                                    src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fservices.png?alt=media" alt="services" />
                            </div>
                        </div>
                        <div className="section-core">
                            <div className="f-c-1 f-c-m">
                                <div className="section-title">Our Offering</div>
                            </div>
                            <div className="f-c-1 f-c-m">
                                <h3 className="core-title">
                                    NEARSHORE DEVELOPMENT
                                </h3>
                            </div>
                            <div className="f-c-1 f-c-m">
                                <p className="paragraph-content">
                                    Bringing together interdisciplinary teams to ensure that client's projects are
                                    successful.
                                </p>
                            </div>
                            <div className="f-c-1 f-c-m">
                                <h3 className="core-title">
                                    WEB & MOBILE APP DEVELOPMENT
                                </h3>
                            </div>
                            <div className="f-c-1 f-c-m">
                                <p className="paragraph-content">
                                    Expertise on state of the art technologies to take client´s business to the next level.
                                </p>
                            </div>
                            <div className="f-c-1 f-c-m">
                                <Link className="see-more" to="/services">See more</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section name="about-us" id="about-us" className="about-us">
                    <div className="section-content">
                        <div className="section-header about-us-header">
                            <div className="img-c-header">
                                <img className="section-header-img" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fabout-us.jpg?alt=media" alt="about-us" />
                            </div>
                        </div>
                        <div className="section-core">
                            <div className="f-c-1 f-c-m">
                                <div className="section-title">About us</div>
                            </div>
                            <div className="f-c-1 f-c-m">
                                <h3 className="core-title">
                                    Staffing provided by Colombian-based developers
                                </h3>
                            </div>
                            <div className="f-c-3 f-c-m">
                                <p className="paragraph-content">
                                    CilaSolutions is a software development services company that teams up with North American
                                    based
                                    software developers to augment their teams with senior programming experience delivered as
                                    an
                                    outsourced service.
                                </p>
                            </div>
                            <div className="f-c-1 f-c-m"></div>
                        </div>
                    </div>
                </section>
                <section name="contact" id="contact" className="contact">
                    <div className="section-content">
                        <div className="section-core contact-us">
                            <div className="f-c-1 f-c-m">
                                <h3 className="core-title">Interested in growing your IT team with CilaSolutions?</h3>
                            </div>
                            <div className="f-c-1 f-c-m">
                                <div className="section-title">Contact us!</div>
                            </div>
                            <form id="cila-contact-form" className="cila-contact-form" action="https://formspree.io/xnqgkodd"
                                method="POST">
                                <div className="cila-contact-form-row f-c-2">
                                    <div className="f-c-1 f-c-m">
                                        <label className="cila-contact-form-label">
                                            Your email
                                        </label>
                                    </div>
                                    <div className="f-c-1 f-c-m">
                                        <input className="cila-contact-form-input" type="text" name="email" required />
                                    </div>
                                </div>
                                <div className="cila-contact-form-row f-c-2">
                                    <div className="f-c-1 f-c-m">
                                        <label className="cila-contact-form-label">
                                            Your contact number
                                        </label>
                                    </div>
                                    <div className="f-c-1 f-c-m">
                                        <input className="cila-contact-form-input" type="text" name="telephone" />
                                    </div>
                                </div>
                                <div className="cila-contact-form-row f-c-4">
                                    <div className="f-c-1 f-c-m">
                                        <label className="cila-contact-form-label">
                                            Your message
                                        </label>
                                    </div>
                                    <div className="f-c-3 f-c-m">
                                        <textarea className="cila-contact-form-input cila-textarea" name="message"
                                            required></textarea>
                                    </div>
                                </div>
                                <div className="cila-contact-form-row cila-send-button-container f-c-1">
                                    <div className="f-c-1 f-c-m">
                                        <button className="cila-send-button" type="submit">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <section name="footer" id="footer">
                    <div className="section-content">
                        <div className="section-core">
                            <div className="f-c-1 f-c-m f-direction-c footer">
                                <div className="flex-d f-c-m f-c-4">
                                    <h3 className="footer-title">
                                        Interested to grow your IT team with us?
                                    </h3>
                                </div>
                                <div className="flex-d f-c-m f-c-2    ">
                                    <h3 className="footer-title">
                                        Our contact Info
                                    </h3>
                                </div>
                                <div className="f-c-6 f-c-m f-direction-c" style={{ justifyContent: 'flex-start' }}>
                                    <div className="flex-d f-c-m foo">
                                        <i className="fas fa-home"></i>
                                        <a className="footer-info">1st street #11E-76 San José de Cúcuta</a>
                                    </div>
                                    <div className="flex-d f-c-m foo">
                                        <i className="fas fa-envelope-open"></i>
                                        <a href="mailto:martha.sandoval@cilasolutions.com"
                                            className="footer-info">martha.sandoval@cilasolutions.co</a>
                                    </div>
                                    <div className="flex-d f-c-m foo">
                                        <i className="fab fa-whatsapp"></i>
                                        <a href="https://api.whatsapp.com/send?phone=573112829985&text=Hola%2C%20deseo%20adquirir%20un%20soporte%20con%20ustedes"
                                            className="footer-info">+57 3112829985</a>
                                    </div>
                                    <div className="flex-d f-c-m foo">
                                        <i className="fas fa-phone"></i>
                                        <a target="_blank" rel="noreferrer" href="tel:+5775729831" className="footer-info">(+57 ) 7 5729831</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <a href="#contact" id="contact-button" className="contact-button"><i className="fas fa-pen-alt"></i></a>
        </>
    );
}

export default MobileContent;

