import React from 'react';
import Helmet from 'react-helmet';

const ServicesMobile = ({ }) => {
  return (
    <>
      <Helmet>
        <title>CilaSolutions - Services</title>
      </Helmet>
      <section id="services-mobile">
        <div className="services-mobile">
          <div className="core-title">
            Our Technologies
          </div>
          <div className="technologies">
            <div className="technology-mask bg-angular">
              <img className="technology" title="Angular" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fangularlogo.png?alt=media" />
            </div>
            <div className="technology-mask bg-csharp">
              <img className="technology" title="Csharp" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fcsharplogo.png?alt=media" />
            </div>
            <div className="technology-mask bg-php">
              <img className="technology" title="phplogo" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fphplogo400.png?alt=media" />
            </div>
            <div className="technology-mask bg-node">
              <img className="technology" title="nodelogo" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fnodejslogo.png?alt=media" />
            </div>
            <div className="technology-mask bg-react">
              <img className="technology" title="reactlogo" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Freactjslogo.png?alt=media" />
            </div>
            <div className="technology-mask bg-html5">
              <img className="technology" title="html5logo" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fhtml5logo.png?alt=media" />
            </div>
            <div className="technology-mask bg-java">
              <img className="technology" title="javalogo" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fjavalogo.png?alt=media" />
            </div>
            <div className="technology-mask bg-python">
              <img className="technology" title="pythonlogo" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fpythonlogo.png?alt=media" />
            </div>
            <div className="technology-mask bg-android">
              <img className="technology" title="androidlogo" src="https://firebasestorage.googleapis.com/v0/b/cilasolutions.appspot.com/o/img%2Fandroidlogo.png?alt=media" />
            </div>
          </div>
          <p className="paragraph-content">
            CilaSolutions provides profesional and talented programmers to software
            development companies looking for additional support or Project resources
          </p>
          <p className="paragraph-content">
            CilaSolutions provides high levels of English competency, cultural alignment and
            cost-effective services to provide a value added extensión to any tech team.
          </p>
        </div>
      </section>
    </>
  );
}

export default ServicesMobile;